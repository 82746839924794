#home {
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    font-family: 'Raleway', Verdana;
    text-transform: lowercase;
    color: white;
    background: black;
    word-spacing: 0.25em;
    line-height: 1.5em;
    overflow-x: hidden;
}

#footer {
    position: fixed;
    display: block;
    text-align: center;
    font-size: 0.85em;
    letter-spacing: 0.03em;
    word-spacing: 0.25em;
    line-height: 1.5em;
    left: 50%;
    bottom: 0;
    margin: 0 auto;
    transform: translate(-50%, 0);
}

#footer:first-child {
    padding-bottom: 0.3em;
}

#menu {
    white-space: nowrap;
    display: flex;
    flex-wrap: nowrap;
    position: fixed;
    height: 20%;
    top: 30%;
    /* 40% - height/2 */
}

/* #arrow {
    white-space: nowrap;
    display: flex;
    flex-wrap: nowrap;
    position: fixed;
    height: 4%;
    width: 5%;
    top: 60%;
    right: 0;
    transform: translate(-50%, -75%);
} */

.item {
    text-decoration: none;
    color: inherit;
    position: relative;
    display: flex;
    padding: 0% 2%;
    align-items: center;
    justify-content: center;
    font-size: 1.7em;
}

.unreleased {
    color: gray;
    cursor: default;
    pointer-events: none;
}

.fade-in {
    -webkit-animation: fade-in 2s forwards;
    -moz-animation: fade-in 2s forwards;
    -o-animation: fade-in 2s forwards;
    -ms-animation: fade-in 2s forwards;
    animation: fade-in 2s forwards;
}

.zooming {
    animation: zoom 3.5s forwards;
}

@keyframes zoom {
    to {
        transform: scale(200);
        /* blackface-visibility: hidden; */
    }
}

@keyframes white_out {
    to {
        background: white;
    }
}

@keyframes fade_out {
    to {
        opacity: 0;
    }
}

@keyframes fade-in {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}