#main {
  font-family: 'Raleway', Verdana;
  word-spacing: 0.25em;
  line-height: 1.5em;
  color: black;
  background: white;
  padding: 0 20vw;
  display: block;
  padding-top: 10em;
  animation: fade-in 2s forwards;
}

#main section:first-of-type::first-letter{
  font-size: 600%;
}

.story > h1 {
  /* font-family: 'Raleway', Verdana; */
  padding-top: 10px;
  font-size: 350%;
  line-height: 79px;
  white-space: nowrap;
  z-index: 2;
  position: relative;
}

.story a {
  color: black;
}

#main section {
  padding: 0.5em 0;
}

#main section::first-letter {
  font-size: 400%;
}

.wide-first::first-letter {
  font-size: 400%;
  padding-right: 2px;
}

#main p {
  text-indent: 1em;
  font-size: large;
  line-height: 200%;
}

#main a {
  text-decoration: none;
}

.title {
  padding-top: 10px;
  font-size: 350%;
  line-height: 79px;
  white-space: nowrap;
  z-index: 2;
  position: fixed;
  top: 20px;
  left: 8vw;
  height: max-content;
  writing-mode: vertical-lr;
}

.story_list {
  position: relative;
}

.story {
  margin-top: 85px;
}

.bullet {
  float: left;
  margin-right: -55px;
  background: white;
  border-style: solid;
  border-color: black;
  border-width: 2px;
  height: 75px;
  width: 75px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.bullet > img {
  position: absolute;
}

.line {
  background: black;
  z-index: -1;
  width: 2px;
  height: 110%;
  position: absolute;
  left: 37.5px;
  top: -95px;
}

#dog {
  mix-blend-mode: multiply;
  position: fixed;
  width: 2.25em;
  height: 2.25em;
  top: 3em;
  right: 3em;
}

#return {
  mix-blend-mode: multiply;
  position: fixed;
  width: 2.25em;
  height: 2.25em;
  top: 3em;
  left: 3em;
}

.fade-in {
  animation: fade-in 2s forwards;
}

@keyframes          fade-in { from { opacity: 0; } to { opacity: 1; }}