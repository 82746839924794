#main {
    font-family: 'Raleway', Verdana;
    word-spacing: 0.25em;
    line-height: 1.5em;
    color: black;
    background: white;
    padding: 0 20vw;
    display: block;
    animation: fade-in 2s forwards;
}

.title {
    font-size: 300%;
    margin-top: 1em;
    margin-bottom: 0;
    line-height: 0.75em;
}

#dog {
    mix-blend-mode: multiply;
    position: fixed;
    width: 2.25em;
    height: 2.25em;
    top: 3em;
    left: 3em;
}

.summary {
    margin: 2em 0;
}

.featured {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%;
    margin-bottom: 2%;
    object-fit: scale-down;
}

.featured > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}

.credits {
    display: flex;
    justify-content: space-between;
}

.reveal_on_hover {
    opacity: 0;
    transition:0s 180s;
}

.reveal_on_hover:hover {
    opacity: 1;
    transition: 1s;
}

.spacer {
    height: 15vh;
}

.form {
    display: flex;
}

.email, .text {
    width: 80%;
}

.text, .submit {
    display: none;
}

.form > button {
    margin-left: 2%;
    width: 20%;
    background-color: white;
    border-radius: 5px;
    border-width: 2px;
}

.form > button:hover, form > span:hover {
    border-width: 2px;
    font-weight: bold;
}

.form > button:focus, form > span:focus {
    background-color: #f0f0f0;
}

.fade_in {
    animation: fade-in 2s forwards;
}

@keyframes          fade-in { from { opacity: 0; } to { opacity: 1; }}